.whatsapp-btn {
    display: inline-flex;
    align-items: center;
    transition: transform 0.5s ease;
  }
  
  .whatsapp-btn:hover {
    transform: scale(1.05) rotateY(20deg);
  }
  
  .whatsapp-logo {
    width: 30px;
    margin-right: 8px;
    transition: transform 0.5s ease;
  }
  
  .whatsapp-btn:hover .whatsapp-logo {
    transform: rotateY(-20deg);
  }
  